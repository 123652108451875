var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "m-help mt-4" },
    [
      _vm.detail
        ? [
            _vm.detail.Definition && _vm.detail.Definition.length
              ? _c("div", { staticClass: "mb-2" }, [
                  _c(
                    "p",
                    {
                      staticClass: "body-2 font-weight-bold text-none ",
                      staticStyle: {
                        "line-height": "20px",
                        color: "rgb(102, 102, 102)"
                      }
                    },
                    [_vm._v("Definition : ")]
                  ),
                  _c("p", {
                    staticClass: "body-1 font-weight-regular",
                    staticStyle: { color: "rgb(102, 102, 102)" },
                    domProps: { innerHTML: _vm._s(_vm.detail.Definition[0]) }
                  })
                ])
              : _vm._e(),
            _vm.detail.kbs && _vm.detail.kbs.length
              ? _c("div", { staticClass: "mb-2" }, [
                  _c(
                    "p",
                    {
                      staticClass: "body-2 font-weight-bold",
                      staticStyle: {
                        "line-height": "20px",
                        color: "rgb(102, 102, 102)"
                      }
                    },
                    [_vm._v("Useful KB(s) :")]
                  ),
                  _c(
                    "ul",
                    {
                      staticClass:
                        "body-1 font-weight-regular;color: rgb(102, 102, 102);"
                    },
                    _vm._l(_vm.detail.kbs, function(kb, i) {
                      return _c("li", {
                        key: i,
                        staticStyle: { color: "rgb(102, 102, 102)" },
                        domProps: { innerHTML: _vm._s(kb) }
                      })
                    }),
                    0
                  )
                ])
              : _vm._e(),
            _vm.detail.notes && _vm.detail.notes.length
              ? _c("div", { staticClass: "mb-2" }, [
                  _c(
                    "p",
                    {
                      staticClass: "body-2 font-weight-bold",
                      staticStyle: {
                        "line-height": "20px",
                        color: "rgb(102, 102, 102)"
                      }
                    },
                    [_vm._v("Notes :")]
                  ),
                  _c(
                    "ul",
                    {
                      staticClass: "body-1 font-weight-regular",
                      staticStyle: { color: "rgb(102, 102, 102)" }
                    },
                    _vm._l(_vm.detail.notes, function(kb, i) {
                      return _c("li", {
                        key: i,
                        staticStyle: { color: "rgb(102, 102, 102)" },
                        domProps: { innerHTML: _vm._s(kb) }
                      })
                    }),
                    0
                  )
                ])
              : _vm._e()
          ]
        : [
            _c("fy-vue-skeleton", {
              attrs: {
                animation: "fade",
                rowOptions: { padding: "10px" },
                childOptions: [
                  {
                    type: "repeat",
                    list: [
                      {
                        span: 24,
                        type: "card",
                        justify: "flex-start",
                        align: "flex-start",
                        options: {
                          height: "30px",
                          width: "10%",
                          marginTop: "10px"
                        }
                      },
                      {
                        span: 24,
                        type: "list",
                        options: {
                          repeat: 3,
                          list: [
                            { height: "20px", width: "100%", marginTop: "10px" }
                          ]
                        }
                      }
                    ]
                  }
                ]
              }
            })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }