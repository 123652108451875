<template>
    <div class="m-help mt-4">
        <template v-if="detail">
            <div class="mb-2" v-if="detail.Definition && detail.Definition.length">
                <p class="body-2 font-weight-bold text-none "  style="line-height: 20px;color: rgb(102, 102, 102);">Definition : </p>
                <p class="body-1 font-weight-regular" style="color: rgb(102, 102, 102);" v-html="detail.Definition[0]">
                </p>
            </div>
            <div class="mb-2" v-if="detail.kbs && detail.kbs.length">
                <p class="body-2 font-weight-bold" style="line-height: 20px;color: rgb(102, 102, 102);">Useful KB(s) :</p>
                <ul  class="body-1 font-weight-regular;color: rgb(102, 102, 102);" >
                    <li v-for="(kb,i) in detail.kbs" :key="i" v-html="kb" style="color: rgb(102, 102, 102);"></li>
                </ul>
            </div>
            <div class="mb-2" v-if="detail.notes && detail.notes.length">
                <p class="body-2 font-weight-bold" style="line-height: 20px;color: rgb(102, 102, 102);">Notes :</p>
                <ul  class="body-1 font-weight-regular" style="color: rgb(102, 102, 102);">
                    <li v-for="(kb,i) in detail.notes" :key="i" v-html="kb" style="color: rgb(102, 102, 102);"></li>
                </ul>
            </div>
        </template>
        <template v-else>
            <fy-vue-skeleton
                    animation="fade"
                    :rowOptions="{padding : '10px'}"
                    :childOptions="[
        {type: 'repeat', list: [
        { span: 24, type: 'card', justify: 'flex-start', align: 'flex-start', options: { height: '30px', width:'10%' ,marginTop:'10px'} },
        { span: 24, type: 'list', options: { repeat:3,list:[{ height: '20px', width:'100%',marginTop:'10px'}] } },
        ]}]"></fy-vue-skeleton>
        </template>

    </div>
</template>

<script>
    import {mapState} from 'vuex'

    export default {
        name: "Help",
        data(){
            return {
                detail : null
            }
        },
        props : ['mod', 'product'],
        computed: {
            ...mapState('application', ['appInfo']),
        },
        created() {
            this.$store.dispatch('getCmsInfo', this.mod).then(({results}) => {
                let result = results[0].content_items[0].detail[this.product.toLowerCase()]
                this.detail = result
            })
        }
    }
</script>

<style lang='scss'>

</style>